<template>
  <div class="Login container-fluid bg">
    <section class="justify-content-center">
      <b-alert
        style="margin-top: 10px"
        variant="danger"
        v-model="errorCredenciales"
      >
        Credenciales Incorrectas!
      </b-alert>
      <section class="center-all">
        <form class="form-container" @submit.prevent="login">
          <div class="center-horizontal">
            <img
              src="https://jprepresentaciones.com.mx/logo.png"
              class="logo"
            />
            <h2>Iniciar Sesión</h2>
          </div>
          <div class="form-group">
            <label for="emailInput">Correo electrónico</label>
            <input
              required
              type="text"
              class="form-control"
              id="emailInput"
              aria-describedby="emailHelp"
              placeholder="Ingrese su correo electrónico"
              :disabled="this.bloquearForm"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <label for="inputPassword">Contraseña</label>
            <input
              required
              type="password"
              class="form-control"
              id="inputPassword"
              placeholder="Ingrese su contraseña"
              :disabled="this.bloquearForm"
              v-model="password"
            />
          </div>
          <b-form-checkbox
            id="checkbox-1"
            v-model="recordar"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
            :disabled="true"
          >
            <p id="txtRecordarme">Recordar</p>
          </b-form-checkbox>
          <div>
            <b-button
              id="loginButton"
              type="submit"
              block
              variant="primary"
              :disabled="this.bloquearForm"
              ><b-spinner v-if="bloquearForm" small></b-spinner>
              <span v-if="!bloquearForm">ENTRAR</span>
            </b-button>
          </div>
          <div class="form-group" style="margin-top: 10px">
            <b-link id="link-password" @click="abrirModal"
              >¿Olvidaste tu contraseña?</b-link
            >
          </div>
        </form>
      </section>
    </section>

    <b-modal ref="modal-restaurar" hide-footer>
      <section>
        <h4 class="text-center">
          Ingrese el correo del usuario que desea recuperar la contraseña
        </h4>
        <b-form @submit.prevent="steps" class="p-4">
          <b-form-group class="mb-3">
            <b-label for="restore-email">Email</b-label>
            <b-input
              type="text"
              id="restore-email"
              v-model="restoreEmail"
              :disabled="correoEnviado"
            ></b-input>
          </b-form-group>

          <b-form-group class="mb-3" v-if="correoEnviado">
            <b-label for="restore-token">Token</b-label>
            <b-input
              type="text"
              id="restore-token"
              v-model="tempToken"
              :disabled="tokenVerificado"
            ></b-input>
          </b-form-group>

          <b-form-group class="mb-3" v-if="tokenVerificado">
            <b-label for="restore-password">Nueva contraseña</b-label>
            <b-input
              type="text"
              id="restore-password"
              v-model="newPassword"
            ></b-input>
          </b-form-group>
          <b-button type="submit" variant="primary">{{ stepText }}</b-button>
        </b-form>
      </section>
    </b-modal>
  </div>
</template>

<script>
import api from "../utils/api";
import { axiosInstance } from "../utils/axiosInstance";
import Notification from "../utils/notification";

export default {
  data() {
    return {
      bloquearForm: false,
      errorCredenciales: false,
      recordar: null,
      email: "",
      password: "",
      restoreEmail: "",
      tempToken: "",
      newPassword: "",
      correoEnviado: false,
      tokenVerificado: false,
    };
  },
  methods: {
    async login() {
      if (!this.validarCredenciales(this.email, this.password)) {
        this.$notify({
          group: "foo",
          title: "Error al iniciar sesión",
          text: "Ingrese sus datos",
          type: "warn",
        });
        return;
      }

      this.bloquearForm = true;

      try {
        const response = await api("POST", "login", {
          email: this.email,
          password: this.password,
        });

        if (response.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al iniciar sesión",
            text: "Credenciales incorrectas",
            type: "error",
          });
          return;
        }

        this.recordarUsuario(response.token, response.usuario);
        this.$router.push("/app/catalogos/productos");
      } catch (error) {
        this.$notify({
          group: "foo",
          title: "Error al iniciar sesión",
          text: "Credenciales incorrectas",
          type: "error",
        });
      } finally {
        this.bloquearForm = false;
      }
    },
    validarCredenciales(email, password) {
      return (
        password !== "" && password !== null && email !== "" && email !== null
      );
    },
    recordarUsuario(token, usuario) {
      localStorage.token = token;
      sessionStorage.usuario_id = usuario.usuario_id;
      localStorage.usuario_id = usuario.usuario_id;
      sessionStorage.nombre = `${usuario.nombre} ${usuario.apellido}`;
      sessionStorage.admin = usuario.esCliente ? false : true;
      sessionStorage.setItem("subcuenta", Boolean(usuario.usuario_padre_id));
    },
    steps() {
      if (this.tokenVerificado) {
        this.reestablecerPassword();
        return;
      }
      if (this.correoEnviado) {
        this.verificarToken();
        return;
      }
      this.requestRestore();
    },
    async requestRestore() {
      this.bloquearForm = true;

      try {
        await axiosInstance.post(`Restaurar/${this.restoreEmail}`);
        Notification("success", "Restaurar", "Correo enviado", 3000);
        this.correoEnviado = true;
      } catch (error) {
        Notification(
          "error",
          "Error",
          error.response.data.msg ||
            "Hubo un error al solicitar la restauración",
          3000
        );
      } finally {
        this.bloquearForm = false;
      }
    },
    async verificarToken() {
      this.bloquearForm = true;

      try {
        await axiosInstance.post("VerificarTokenRestaurar", {
          email: this.restoreEmail,
          token: this.tempToken,
        });

        this.tokenVerificado = true;

        Notification("success", "Token", "El token ha sido verificado", 3000);
      } catch (error) {
        Notification(
          "error",
          "Token",
          error.response.data.msg || "Hubo un error al verificar el token"
        );
      } finally {
        this.bloquearForm = false;
      }
    },
    async reestablecerPassword() {
      this.bloquearForm = true;

      try {
        await axiosInstance.post('ReestablecerPassword', { email: this.restoreEmail, token: this.tempToken, password: this.newPassword });
        Notification('success', 'Contraseña', 'Contraseña reestablecida', 3000);
        this.$refs['modal-restaurar'].hide();
      } catch (error) {
        Notification('error', 'Contraseña', error.response.data.msg || 'Hubo un error al reestablecer la contraseña', 3000);
      } finally {
        this.bloquearForm = false;
      }
    },
    abrirModal() {
      this.$refs["modal-restaurar"].show();
    },
  },
  computed: {
    stepText() {
      if (this.tokenVerificado) {
        return 'Cambiar contraseña';
      }
      if (this.correoEnviado) {
        return 'Verificar token';
      }
      return 'Solicitar correo';
    }
  }
};
</script>

<style>
.bg {
  background-color: #355070;
  width: 100%;
  height: 100vh;
  background-size: 100%;
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.form-container {
  background: #fff;
  padding: 35px 32px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px #000;
  box-shadow: 0px 0px 10px 0px #0000002e;
  width: 400px;
}

.center-horizontal {
  text-align: center;
}

.logo {
  width: 200px;
  margin-bottom: 24px;
}

#link-password {
  font-size: 17px;
}

#loginButton {
  background-color: #ef9b11;
  border-color: #ef9b11;
  /* margin-top: 10px; */
}

#txtRecordarme {
  font-size: 14px;
}
</style>
