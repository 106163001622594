<template>
  <div id="DetallesPedido">
    <template>
      <header>
        <div>
          <span>Numero de pedido:</span>
          <b-badge>{{ pedido.codigo }}</b-badge>
        </div>
        <div>
          <span>{{ pedido.creacion }}</span>
        </div>
      </header>
      <section>
        <b-overlay :show="loading">
          <b-table-simple small hover>
            <b-thead head-variant="light">
              <b-tr>
                <b-th class="text-center">Partida</b-th>
                <b-th class="text-center">Código</b-th>
                <b-th class="text-center">Descripción</b-th>
                <b-th class="text-center">Cantidad</b-th>
                <b-th class="text-center">Precio</b-th>
                <b-th class="text-center">Subtotal</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(producto, i) of pedido.pedido_productos" :key="i">
                <b-td class="text-center">{{ producto.partida }}</b-td>
                <b-td class="text-center">{{ producto.producto.codigo }}</b-td>
                <b-td>{{ producto.producto.descripcion }}</b-td>
                <b-td class="text-center">{{ producto.cantidad }}</b-td>
                <b-td>
                  <div class="d-flex justify-content-between">
                    <span>$</span>
                    {{ parseFloat(producto.precio).toFixed(2) }}
                  </div>
                </b-td>
                <b-td>
                  <div class="d-flex justify-content-between">
                    <span>$</span>
                    {{ parseFloat(producto.subtotal).toFixed(2) }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
        <div class="totales">
          <div class="total-row">
            <p>Subtotal:</p>
            <p>
              <span>$</span>
              {{ pedido.subtotal | currency }}
            </p>
          </div>
          <div class="total-row">
            <p>I.V.A.:</p>
            <p>
              <span>$</span>
              {{ pedido.iva | currency }}
            </p>
          </div>
          <div class="total-row">
            <p>I.E.P.S.:</p>
            <p>
              <span>$</span>
              {{ pedido.ieps | currency }}
            </p>
          </div>
          <div class="total-row">
            <p>Total:</p>
            <p>
              <span>$</span>
              {{ pedido.total | currency }}
            </p>
          </div>
        </div>
      </section>
      <footer>
        <div>
          <BaseButton @onCta="generarPdf" title="PDF" />
          <BaseButton @onCta="generarExcel" title="Excel" />
          <b-button style="background-color: #355070; margin-left: 2px;" @click="cancelarPedido" size="sm"
            :disabled="loading" v-if="!pedido.FacturaID && !pedido.cancelado">
            <b-spinner small v-if="loading"></b-spinner>
            Solicitar Cancelación
          </b-button>
          <BaseButton title="Aceptar" @onCta="$emit('cerrar')" />
        </div>
      </footer>
    </template>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import moment from "moment";
import Swal from 'sweetalert2';
import { axiosInstance } from '../../../utils/axiosInstance';

export default {
  name: "DetallesPedido",
  props: ['pedido'],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    generarPdf() {
      let doc = new jsPDF({ unit: "px", hotfixes: ["px_scaling"] });
      doc.setFontSize(10);
      let pdfPedidosSrc = document.getElementById("pdf-pedidos");
      pdfPedidosSrc.style.display = "block";
      const codigo = this.pedido.codigo;
      doc.html(document.getElementById("pdf-pedidos"), {
        callback: function (doc) {
          doc.save(`Pedido-${codigo}-${moment().format("DD/MM/YYYY")}.pdf`);
          pdfPedidosSrc.style.display = "none";
        },
        margin: [35,0,100,0],
        width: 1024,
        autoPaging: 'text',
      });
    },
    parseProductosLista() {
      return this.listaProductos.map((producto) => {
        return {
          codigo: producto.codigo,
          nombre: producto.descripcion,
          cantidad: producto.cantidad,
          precio: producto.precio_unitario,
          "precio con iva": producto.iva_total,
        };
      });
    },
    generarExcel() {
      let pdfPedidosSrc = document.getElementById("excel-pedidos");
      pdfPedidosSrc.style.display = "block";
      let table_elt = document.getElementById("tabla-excel-pedidos");
      table_elt.style.marginTop = "1000px";
      let workbook = XLSX.utils.table_to_book(table_elt);
      let ws = workbook.Sheets["Sheet1"];
      var wscols = [
        { wch: 20 }, // "characters"
        { wch: 75 },
      ];

      ws["!cols"] = wscols;
      XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {
        origin: -1,
      });

      XLSX.writeFile(workbook, `Pedido${this.pedido.codigo}.xlsx`);
      pdfPedidosSrc.style.display = "none";
    },
    async cancelarPedido() {
      const result = await Swal.fire({
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: false,
        showDenyButton: false,
        confirmButtonColor: '#355070',
        text: '¿Está seguro de que desea solicitar la cancelación del pedido?',
      });

      if (!result.isConfirmed) {
        return;
      }

      const { value: Comentarios } = await Swal.fire({
        title: "Comente su razón para la cancelación, para tener más oportunidad de ser aceptado.",
        input: "textarea",
        inputPlaceholder: 'Comentarios...',
        inputAutoFocus: true,
        showCancelButton: false,
        confirmButtonColor: '#355070'
      });

      this.loading = true;
      try {
        await axiosInstance.post(`pedidos/cancelacion/${this.pedido.id}`,
          {
            Comentarios
          },
          {
            headers: {
              authorization: localStorage.getItem('token')
            }
          }
        );

        this.makeToast('Cancelación de pedido', 'Cancelación solicitada', 'success', 3000);
      } catch (error) {
        this.makeToast('Cancelación de pedido', error.response.data.msg || 'Hubo un error en el servidor', 'danger', 3000);
      } finally {
        this.loading = false;
      }
    },
  },
  filters: {
    currency(value) {
      return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  emits: ['cerrar']
};
</script>